import "@fontsource/inter"
import "@fontsource/inter/500.css"
import "@fontsource/inter/700.css"
import "modern-normalize"
import React from "react"
import { Layout, PricingPlan, Seo } from "../components"
import "../styles/global.css"
import * as styles from "./index.module.css"

const GCMenu = () => (
  <Layout>
    <Seo title="Bảng Giá" />
    <div className={styles.content}>
      <PricingPlan />
    </div>
  </Layout>
)

export default GCMenu
